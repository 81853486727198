import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import icoAllianz from "../assets/old/img/logos-ase/ico-allianz.png"
import icoExperta from "../assets/old/img/logos-ase/ico-experta.png"
import icoOrbis from "../assets/old/img/logos-ase/ico-orbis.png";
import icoProvincia from "../assets/old/img/logos-ase/ico-provincia.png";
import icoRus from "../assets/old/img/logos-ase/ico-rus.png";
import icoSancor from "../assets/old/img/logos-ase/ico-sancor.png";
import icoCristobal from "../assets/old/img/logos-ase/ico-sancristobal.png";
import icoSura from "../assets/old/img/logos-ase/ico-sura.png";
import icoMercantil from "../assets/old/img/logos-ase/ico-mercantilandina.png";
import icoParana from "../assets/old/img/logos-ase/parana-seguros.png";
import icoGaleno from "../assets/old/img/logos-ase/ico-galeno.png";
import icoBerkley from "../assets/old/img/logos-ase/ico-berkley.png"


import { Helmet } from "react-helmet"

const companias = [
  {
    icono: icoAllianz,
    nombre: "Allianz",
    vinculo:"/siniestros/allianz",
    left: "0",
    top: "0",
    contenido: "Telefónicamente al 0800-888-24324/37 / (+5411) 4329-9191/ 5238-9202.",
  },
  {
    icono: icoExperta,
    nombre: "Experta",
    vinculo:"/siniestros/experta",
    left: "390px",
    top: "0",
    contenido: "Servicio de asistencia: 0800-7777-278.",
  },
  {
    icono: icoOrbis,
    nombre: "Orbis",
    vinculo:"/siniestros/orbis",
    left: "780px",
    top: "0",
    contenido: "Servicio de asistencia: 0800-555-7722 / 0810-666-4001.",
  },
  {
    icono: icoProvincia,
    nombre: "Provincia",
    vinculo:"/siniestros/provincia",
    left: "0",
    top: "318px",
    contenido: "Servicio de asistencia: 0810-222-2444.",
  },
  {
    icono: icoRus,
    nombre: "RUS",
    vinculo:"/siniestros/rus",
    left: "390px",
    top: "318px",
    contenido: "Servicio de asistencia: 0810-888-7080.",
  },
  {
    icono: icoSancor,
    nombre: "Sancor",
    vinculo:"/siniestros/sancor",
    left: "780px",
    top: "318px",
    contenido: "Servicio de asistencia: 0800 777 4643.",
  },
  {
    icono: icoCristobal,
    nombre: "San Cristóbal",
    vinculo:"/siniestros/san-cristobal",
    left: "0",
    top: "636px",
    contenido: "Servicio de asistencia: 0810-222-8887.",
  },
  {
    icono: icoSura,
    nombre: "Sura",
    vinculo:"/siniestros/sura",
    left: "390px",
    top: "636px",
    contenido: "Servicio de asistencia: 0800 999 76925.",
  },
  {
    icono: icoMercantil,
    nombre: "Mercantil Andina",
    vinculo:"/siniestros/mercantil-andina",
    left: "780px",
    top: "636px",
    contenido: "Servicio de asistencia a siniestros: 0800-888-4488. Asistencia Mecánica: 0800-777-2634 ",
  },
  {
    icono: icoParana,
    nombre: "Parana",
    vinculo:"/siniestros/parana",
    left: "0",
    top: "954px",
    contenido: "Servicio de asistencia: 0800-222-7272.",
  },
  {
    icono: icoGaleno,
    nombre: "Galeno",
    vinculo:"/siniestros/galeno",
    left: "390px",
    top: "954px",
    contenido: "Servicio de asistencia: 0800-777-5433.",
  },
  {
    icono: icoBerkley,
    nombre: "Berkley",
    vinculo:"/siniestros/berkley",
    left: "780px",
    top: "954px",
    contenido: "Servicio de asistencia: 0810-555-3031.",
  },
]

export default class extends React.Component {
  componentDidMount() {
    const $ = window.$
    const $grid = $(".isotope-grid")
    $grid.isotope({
      itemSelector: ".grid-item",
      transitionDuration: "0.7s",
      masonry: {
        columnWidth: ".grid-sizer",
        gutter: ".gutter-sizer",
      },
    })
  }

  render() {
    const CompaniaCard = ({ icono, nombre, vinculo, children, left, top }) => (
      <div className="grid-item" style={{ position: "absolute", left: left, top: top }}>
        <div className="card blog-card mb-2">
          <Link className="text-center bg-secondary" to={vinculo}>
            <img className="py-4 mx-auto" src={icono} alt={nombre} />
          </Link>
          <div className="card-body">
            <h5 className="post-title">
              <Link to={vinculo}>{nombre}</Link>
            </h5>
            <p className="text-muted pad-min-siniestros">{children}</p>
          </div>
          <div className="card-footer">
            <Link className="tag-link" to={vinculo}>
              Obtén mas información sobre {nombre}
            </Link>
          </div>
        </div>
      </div>
    )

    return (
      <Layout menuActivo="siniestros">
        <Helmet>
          <title>Siniestros</title>
          <meta
            name="description"
            content="Toda la información que necesitas en caso de siniestros"
          />
        </Helmet>

        <section className="pb-5 pt-5" id="cases">
          <h2 className="h3 block-title text-center mb-5">
            Siniestros<small>Toda la información que necesitas en caso de siniestros.</small>
          </h2>

          <p className="text-center mb-5">
            Comuníquese con nuestros asesores de
            <strong>
              <span className="text-primary">&nbsp;</span>
              <span className="text-primary">
                lunes a viernes de 09:00 a 21:00, sábados de 10:00 a 15:00 y al&nbsp;0810-345-7676 (Líneas Rotativas)
              </span>
              &nbsp;
            </strong>
            o envíe un mail a&nbsp;
            <a href="mailto:siniestros@webdelseguro.com.ar">
              <strong>
                <span className="text-primary">siniestros@webdelseguro.com.ar</span>
              </strong>
            </a>
          </p>

          <div className="container pb-5 mb-3">
            <div className="row">
              {/* Blog Grid */}
              <div className="col-lg-12">
                <div
                  className="isotope-grid cols-3"
                  style={{ position: "relative", height: 2033.63 }}
                >
                  <div className="gutter-sizer" />
                  <div className="grid-sizer" />

                  {/* Post */}
                  {companias.map(compania => (
                    <CompaniaCard
                      key={compania.nombre}
                      icono={compania.icono}
                      nombre={compania.nombre}
                      vinculo={compania.vinculo}
                      left={compania.left}
                      top={compania.top}
                    >
                      {compania.contenido}
                    </CompaniaCard>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
